<template>
	<div class="addboxjuese">
		<div class="topbox">
			<div class="box1">
				<div></div>
				<span>角色管理 ></span>
				<p>新增角色</p>
			</div>
			<div class="twobtn nochoosetext">
				<div @click="clickCancel">取消</div>
				<div class="save" @click="_editRole">保存</div>
			</div>
		</div>
		<div class="box2">
			<div class="box3">
				<div class="title">
					<div>
						<span>*</span>
						角色名称
					</div>
					<input type="text" v-model="addroleinfo.roleName" placeholder="请输入角色名称" />
				</div>
			</div>
			<div class="box3" style="margin-top: 1.25em;">
				<div class="title">
					<div>
						
						角色简介
					</div>
					<textarea v-model="addroleinfo.roleDesc" rows="" cols=""></textarea>
					<!-- <input type="textarea" v-model="addroleinfo.roleName" placeholder="请输入角色名称" /> -->
				</div>
			</div>
<!-- 
			<div class="box3" style="margin-left: 2.5em;">
				<div class="title">
					<span>*</span>
					角色层级
				</div>
				<div class="choosebox">
					<div
						class="onechoose"
						v-for="(item, index) in 4"
						:key="index"
						@click="chooseCengji(index)"
					>
						<div :class="{ activecengji: index === cengjiIndex }">
							<p v-if="index === cengjiIndex"></p>
						</div>
						<span>层级01</span>
					</div>
				</div>
			</div> -->
		</div>
		<div class="tablebox">
			<div class="tabletop">
				<div></div>
				<span>权限设置</span>
			</div>
			<el-tree
				:data="data"
				show-checkbox
				node-key="id"
				ref="tree"
				highlight-current
				:props="defaultProps"
				
				@check="handleCheckChange1"
			></el-tree>
			<!-- <el-table
				:data="tableData"
				:span-method="objectSpanMethod"
				border
				style="width: 100%; margin-top: 20px"
			>
				<el-table-column prop="first" label="一级栏目" width="200"></el-table-column>
				<el-table-column prop="second" label="二级栏目" width="200">
					<template slot-scope="scope">
						<div class="secondbox nochoosetext" @click="clickChooseSecond(scope.row)">
							<img v-if="scope.row.chooseSecond" src="@/assets/btnicon/image8.png" >
							<div v-else></div>
							<span>{{scope.row.second}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="third" label="权限功能">
					<template slot-scope="scope">
						<div class="allthrid nochoosetext">
							<div class="thridbox" v-for="(item,index) in scope.row.third" @click="chooseThird(scope.row,index)">
								<img src="@/assets/btnicon/image8.png" v-if="scope.row.thirdIndex.includes(index)" >
								<div v-else class=""></div>
								<span>{{item}}</span>
							</div>
						</div>
						
					</template>
				</el-table-column>
			</el-table> -->
		</div>
	</div>
</template>

<script>
import { addRole, permissionTree ,roleDetail,editRole} from '@/network/api.js';
export default {
	data() {
		return {
			cengjiIndex: 0,
			data: [],
			permissionsList: [],     // 权限列表
			permissionsIdList: [],     // 权限id列表
			addroleinfo: {
				permissionParams: [],
				roleDesc: '',
				roleId: '',
				roleName: ''
			},
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			chooseList: [],   // 回显数据
			tableData: [
				{
					first: '公海管理',
					second: '公海列表',
					third: ['查看', '分配', '领取', '新增'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '线索管理',
					second: '我的线索',
					third: ['新增', '跟进', '查看', '编辑', '新增商机'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '线索管理',
					second: '团队线索',
					third: ['新增', '跟进', '查看', '编辑', '新增商机'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '商机管理',
					second: '我的商机',
					third: ['跟进', '编辑', '删除', '转为客户', '转入公海'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '商机管理',
					second: '团队商机',
					third: ['跟进', '编辑', '删除', '转为客户', '转入公海'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '客户管理',
					second: '我的客户',
					third: ['跟进', '查看', '编辑'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '客户管理',
					second: '团队客户',
					third: ['跟进', '查看', '编辑'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '日程管理',
					second: '日程列表',
					third: ['查看', '编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '合同管理',
					second: '合同列表',
					third: ['编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '组织机构',
					second: '部门管理',
					third: ['编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '组织机构',
					second: '职务管理',
					third: ['编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '组织机构',
					second: '角色管理',
					third: ['编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				},
				{
					first: '组织机构',
					second: '账号管理',
					third: ['编辑', '删除'],
					chooseSecond: false,
					thirdIndex: []
				}
			],
			
		};
	},
	created() {
		this._permissionTree();
		this.addroleinfo.roleId = this.$route.query.roleId
		this._roleDetail()
	},
	computed: {
		thirdIncludes(index, arr) {
			return arr.includes(index);
		}
	},
	methods: {
		// 回显用户权限
		formatUserPermission(info) {
			
			info.forEach(item => {
				this.addroleinfo.permissionParams.push({
					level: item.level,
					parentPermissionId: item.parentPermissionId,
					permissionId: item.permissionId,
				})
				if (item.permissionChildren && item.permissionChildren.length > 0) {
					this.formatUserPermission(item.permissionChildren)
				}
			})
			
		},
		// 获取角色详情
		async _roleDetail() {
			const res = await roleDetail({roleId: this.addroleinfo.roleId})
			console.log('角色详情',res);
			this.addroleinfo.roleDesc = res.data.roleDesc
			this.addroleinfo.roleName = res.data.roleName
			this.formatUserPermission(res.data.permissionTreeVoList); 
			this.addroleinfo.permissionParams.forEach(item => {
				this.$refs.tree.setChecked(item.permissionId,true)
			})
			console.log("角色详情获取的权限",this.addroleinfo.permissionParams);
		},
		// 添加角色
		async _editRole() {
			console.log(this.addroleinfo.permissionParams);
			// return
			let {permissionParams,roleDesc,roleName} = this.addroleinfo
			if (!roleName) return this.$myMessage('请输入角色名称','error')
			
			if (permissionParams.length < 1) return this.$myMessage('请至少选择一种权限','error')
			const res = await editRole(this.addroleinfo)
			this.$myMessage('编辑成功','success')
			this.$router.back()
		},
		handleCheckChange1(data,data1) {
				this.addroleinfo.permissionParams = []
			console.log(data,data1);
			// if (data1.checkedNodes.length > 0 && data1.halfCheckedNodes.length > 0) {
			// 	this.addroleinfo.permissionParams = []
			// } else {
				data1.checkedNodes.forEach(item => {
					this.addroleinfo.permissionParams.push({
						level: item.level,
						parentPermissionId: item.parentPermissionId,
						permissionId: item.id
					})
				})
				data1.halfCheckedNodes.forEach(item => {
					this.addroleinfo.permissionParams.push({
						level: item.level,
						parentPermissionId: item.parentPermissionId,
						permissionId: item.id
					})
				})
			// }
			 
		},

		// reduceInfo(info) {
		// 	let isarray = this.addroleinfo.permissionParams.map(item => item.permissionId)
		// 	info.forEach(item => {
				
		// 		if (isarray.includes(item.id)) {
		// 			this.addroleinfo.permissionParams.splice(isarray.indexOf(item.id),1)
		// 		}
		// 		if (item.children && item.children.length > 0) {
		// 			this.reduceInfo(item.children);
		// 		}
		// 	});
		// },
		// addInfo(info) {
		// 	let isarray = this.addroleinfo.permissionParams.map(item => item.permissionId)
		// 	info.forEach(item => {
		// 		if (!isarray.includes(item.id)) {
		// 			this.addroleinfo.permissionParams.push({
		// 				level: item.level,
		// 				parentPermissionId: item.parentPermissionId,
		// 				permissionId: item.id,
		// 				label: item.label
		// 			});
		// 		}
				
		// 		if (item.children && item.children.length > 0) {
		// 			this.addInfo(item.children);
		// 		}
		// 	});
		// },
		// handleCheckChange(data, checked, indeterminate) {
		// 	// console.log(data, checked,indeterminate);
		// 	// return
		// 	let isarray = this.addroleinfo.permissionParams.map(item => item.permissionId)
		// 	if (checked && !indeterminate) {
		// 		if (!isarray.includes(data.id)) {
		// 			this.addroleinfo.permissionParams.push({
		// 				level: data.level,
		// 				parentPermissionId: data.parentPermissionId,
		// 				permissionId: data.id,
		// 				label: data.label
		// 			});
		// 		}
				
		// 		if (data.children && data.children.length > 0) {
		// 			this.addInfo(data.children);
		// 		}
		// 	} else if (!checked && !indeterminate) {
		// 		if (!isarray.includes(data.id)) {
		// 			this.addroleinfo.permissionParams.push({
		// 				level: data.level,
		// 				parentPermissionId: data.parentPermissionId,
		// 				permissionId: data.id,
		// 				label: data.label
		// 			});
		// 		}
				
		// 		if (data.children && data.children.length > 0) {
		// 			this.addInfo(data.children);
		// 		}
		// 	} else if (!checked && !indeterminate)  {
		// 		if (!indeterminate) {
					
		// 		}
		// 		this.addroleinfo.permissionParams.splice(isarray.indexOf(data.id),1)
		// 		if (data.children && data.children.length > 0) {
		// 			this.reduceInfo(data.children);
		// 		}
		// 	}
		// },
	
		formatData(data) {
			let data1 = data.map(item => {
				let data2 = {
					id: item.permissionId,
					parentPermissionId: item.parentPermissionId,
					label: item.permissionDesc,
					level: item.level,
					children: []
				};
				if (item.permissionChildren && item.permissionChildren.length > 0) {
					data2.children = this.formatData(item.permissionChildren);
				}
				return data2;
			});
			return data1;
		},
		// 将权限展开
		unwindData(data) {
			
		},
		getPermissionsList(info) {
		
			this.permissionsList.push({
				level: info.level,
				parentPermissionId: info.parentPermissionId,
				permissionId: info.permissionId,
				// permissionDesc: info.permissionDesc
			})
			if (info.permissionChildren && info.permissionChildren.length > 0) {
				info.permissionChildren.forEach(item => {
					this.getPermissionsList(item)
				})
			}
		},
		// 获取权限树
		async _permissionTree() {
			const res = await permissionTree();
			// console.log('权限树', res);
			this.data = this.formatData(res.data);
			res.data.forEach(item => {
				this.getPermissionsList(item)
			})
			this.permissionsIdList = this.permissionsList.map(item => item.permissionId)
			console.log('权限树',this.permissionsList);
		},
		// 点击取消
		clickCancel() {
			this.$router.back();
		},
		// 点击选择二级栏目
		clickChooseSecond(info) {
			info.chooseSecond = !info.chooseSecond;
			if (!info.chooseSecond) {
				info.thirdIndex = [];
			}
		},
		// 点击选择功能列表
		chooseThird(info, index) {
			let indexarr = info.thirdIndex;
			// indexarr.includes(index) ? indexarr.splice(indexarr.indexOf(index),1) : indexarr.push(index)
			if (indexarr.includes(index)) {
				indexarr.splice(indexarr.indexOf(index), 1);
			} else {
				indexarr.push(index);
			}
			indexarr.length > 0 ? (info.chooseSecond = true) : '';
		},

		// 表格合并
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0) {
					return {
						rowspan: 1,
						colspan: 1
					};
				} else if (rowIndex === 1) {
					return {
						rowspan: 2,
						colspan: 1
					};
				} else if (rowIndex === 3) {
					return {
						rowspan: 2,
						colspan: 1
					};
				} else if (rowIndex === 5) {
					return {
						rowspan: 2,
						colspan: 1
					};
				} else if (rowIndex === 7) {
					return {
						rowspan: 1,
						colspan: 1
					};
				} else if (rowIndex === 8) {
					return {
						rowspan: 1,
						colspan: 1
					};
				} else if (rowIndex === 9) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		},
		// 选择层级
		chooseCengji(index) {
			this.cengjiIndex = index;
		}
	}
};
</script>

<style scoped lang="scss">
.thridbox {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 2.5em 0 0;
	div {
		width: 0.875em;
		height: 0.875em;
		background: #ffffff;
		border-radius: 0.125em;
		border: 1px solid #cccccc;
	}
	img {
		width: 0.875em;
		height: 0.875em;
	}
	span {
		margin: 0 0 0 0.625em;
	}
}
.allthrid {
	display: flex;
	align-items: center;
}
.secondbox {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	div {
		width: 0.875em;
		height: 0.875em;
		background: #ffffff;
		border-radius: 0.125em;
		border: 1px solid #cccccc;
	}
	img {
		width: 0.875em;
		height: 0.875em;
	}
	span {
		margin: 0 0 0 0.625em;
	}
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
.tabletop {
	display: flex;
	align-items: center;
	div {
		width: 0.25em;
		height: 1.1875em;
		background-color: #0463ec;
		border-radius: 0.125em;
		margin: 0 0.625em 0 0;
	}
	span {
		font-size: 1em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #303030;
	}
}
.tablebox {
	width: calc(100% - 2.5em);
	background-color: #ffffff;
	margin: 0.9375em auto;
	padding: 1.25em;
}
.onechoose {
	margin: 0 3.75em 0 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	div {
		width: 0.875em;
		height: 0.875em;
		background: #ffffff;
		border: 1px solid #cccccc;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		&.activecengji {
			border: 1px solid #2e78f6;
		}
		p {
			width: 0.5em;
			height: 0.5em;
			background-color: #2e78f6;
			border-radius: 50%;
		}
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
		margin: 0 0 0 0.625em;
	}
}
.choosebox {
	margin: 1.325em 0 0;
	display: flex;
	align-items: center;
	padding: 0 0.9375em;
}
.box3 {
	input:focus {
		border-color: #2e77f6;
	}
	textarea {
		padding: 0.625rem;
		outline: none;
		width: 38.4375em;
		height: 6em;
		border-radius: 0.25em;
		border: 1px solid #eeeeee;
		margin: 0.9375em 0 0 0;
		font-size: 0.9375em;
		max-width: 60em;
	}
	input {
		outline: none;
		width: 38.4375em;
		height: 2.5em;
		border-radius: 0.25em;
		border: 1px solid #eeeeee;
		padding: 0 1.25em;
		margin: 0.9375em 0 0 0;
		font-size: 0.9375em;
	}
}
.title {
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #444444;
	span {
		color: #F22E18;
		margin: 0 0.625em 0 0;
	}
}
.box2 {
	// display: flex;
	// align-items: center;
	width: calc(100% - 2.5em);
	// height: 3.875em;

	background-color: #ffffff;
	margin: 0 auto;
	padding: 1.25em;
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 8.625em;
		height: 2.125em;
		border-radius: 0.25em;
		border: 1px solid #2d75f5;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #2e76f5;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 1.25em 0 1.875em;
		}
	}
}
.box1 {
	display: flex;
	align-items: center;
	div {
		width: 0.25em;
		height: 1.1875em;
		background-color: #0463ec;
		border-radius: 0.125em;
		margin: 0 0.625em 0 1.25em;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #808080;
	}
	p {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		margin: 0 0 0 0.625em;
	}
}
.topbox {
	display: flex;
	align-items: center;
	width: calc(100% - 2.5em);
	height: 3.875em;
	justify-content: space-between;
	background-color: #ffffff;
	margin: 0.9375em auto;
}
@media screen and (max-width: 1920px) {
	.addboxjuese {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.addboxjuese {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.addboxjuese {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.addboxjuese {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.addboxjuese {
		font-size: 12px;
	}
}
</style>
